import ButtonSwitch, { ButtonWrapperProps } from "@components/form/button-switch";
import { Button } from "@components/new-ui/button";
import { CloseButton, Dialog, DialogContent } from "@components/new-ui/dialog";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@components/new-ui/select";
import { usePreferences } from "@contexts/PreferencesContext";
import useLanguages from "@hooks/use-languages";
import { Dict } from "@hooks/useLanguage";
import { getMessage } from "@hooks/use-messages";
import useNow from "@hooks/useNow";
import usePopup from "@hooks/usePopup";
import useRewards from "@hooks/useRewards";
import useSocket from "@hooks/useSocket";
import { logout, useUserSession } from "@hooks/useUserSession";
import useWallet from "@hooks/useWallet";
import { cn, removeDateOffset, timeLeft } from "@lib/utils";
import API from "@lib/api/api";
import { NODE_API2 } from "@lib/api/nodeApi";
import StorageService from "@lib/services/Storage.Service";
import userRakebackBoosted from "@recoil/rakebackBoosted/atom";
import { chatLanguageAtom, referrerAtom } from "@store/global";
import { differenceInMilliseconds } from "date-fns";
import { useAtom, useAtomValue } from "jotai";
import dynamic from "next/dynamic";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import router from "next/router";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { useRecoilState } from "recoil";
import { BalanceDropdown } from "./balance-dropdown";
import { CasinoNavBarIcon, ChatIcon, MenuIcon, SearchIcon, SportsNavBarIcon, WalletIcon } from "./icons";
import Image from "next/image";
const HeaderContent = dynamic(() => import("./header-content"));
const SearchModal = dynamic(() => import("@components/SearchModal"));
export default function NewHeader() {
  const now = useNow();
  const [rakebackBoosted, setRakebackBoosted] = useRecoilState(userRakebackBoosted);
  const {
    mutate,
    userData,
    addTokenHeader
  } = useUserSession();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const openedModal = useRef(false);
  const affiliateRedeemed = useRef(false);
  const [affiliateApproved, setAffiliateApproved] = useState(null);
  const refSendPromo = useRef(false);
  const refPromoSent = useRef(false);
  const setMessage = usePopup();
  const {
    balanceMutate,
    setUserBalanceType
  } = useWallet();
  const referrer = useAtomValue(referrerAtom);
  const {
    data: rewardsData,
    mutate: getRewardsData
  } = useRewards();
  const {
    preferences
  } = usePreferences();
  const removeQueryParam = useCallback((param: string) => {
    const params = new URLSearchParams(searchParams);
    params.delete(param);
    const newQuery = params.toString();
    const newPath = newQuery ? `${pathname}?${newQuery}` : pathname;

    // Use replace to avoid adding to history
    router.replace(newPath, undefined, {
      shallow: true,
      scroll: false
    });
  }, [pathname, searchParams]);
  const socket = useSocket(process.env.NEXT_PUBLIC_ALERTS_SOCKET_URL);
  const balance_update = useCallback(() => {
    return Promise.all([balanceMutate(), mutate()]);
  }, [balanceMutate, mutate]);
  useEffect(() => {
    socket?.on("balance_update", balance_update);
    return () => {
      socket?.off("balance_update", balance_update);
    };
  }, [socket, balance_update]);

  //Logs the user out if delete is present
  useEffect(() => {
    if (userData?.deleted) {
      logout();
    }
  }, [userData]);
  useEffect(() => {
    setTimeout(() => {
      if (!userData && affiliateApproved) {
        router.push(`/?modal=auth&tab=register`);
      }
      setAffiliateApproved(null);
    }, 500);
  }, [userData, affiliateApproved]);
  const checkReferralCode = useCallback(code => {
    // Add v1 prefix to match API structure
    return API.post("affiliate/validate", {
      affiliate_code: code
    }).then(res => {
      if (res.status === 202) {
        StorageService.setReferralCode(code);
        setAffiliateApproved(true);
      }
    }).catch(error => {
      console.log(error);
      setAffiliateApproved(null);
    });
  }, []);

  //Collect the referral code from the url
  useEffect(() => {
    // Only process if there's a referral code and we're on client side
    if (!searchParams.has("r") || typeof window === "undefined") return;
    const code = searchParams.get("r");

    // Always remove the referral parameter from URL first
    removeQueryParam("r");

    // Check if referrer is from Google before processing
    if (!referrer?.startsWith("https://google.")) {
      openedModal.current = true;
      checkReferralCode(code).catch(console.error);
    }
    // If it's from Google, we just removed the parameter and do nothing else
  }, [checkReferralCode, removeQueryParam, searchParams, referrer]);
  useEffect(() => {
    //No # and it detects the full text
    if (searchParams.has("promo")) {
      openedModal.current = true;
      StorageService.setLocalStorageValue("promo", searchParams.get("promo"));
      removeQueryParam("promo");
    } else if (typeof window !== "undefined") {
      const fragmentIdentifier = window.location.hash;

      // If no referral code was found in the query string, check the fragment identifier
      if (fragmentIdentifier) {
        refSendPromo.current = true;
        StorageService.setLocalStorageValue("promo", fragmentIdentifier);
        removeQueryParam("promo");
      }
    }
  }, [removeQueryParam, searchParams]);

  //Open the add affiliate modal if the user is eligible

  //assumed that this will get called after the router has processes the query.
  useEffect(() => {
    //If they have an affiliate code, open the affiliate modal
    if (userData && !affiliateRedeemed.current) {
      affiliateRedeemed.current = true;
      const affiliate = StorageService.getReferralCode();
      if (userData?.affiliate?.eligible && affiliate) {
        router.push(`/?modal=wallet&tab=affiliate`);
      } else {
        StorageService.removeReferralCode();
      }
    }
    const localPromo = StorageService.getLocalStorageValue("promo");

    //Send the promo code if the user is logged in
    if (userData && localPromo && !refPromoSent.current) {
      refPromoSent.current = true;
      const redeemPromoCode = async () => {
        try {
          const res = await NODE_API2.post("v1/promotions/redeem-code", {
            promotion_code: localPromo
          }, addTokenHeader());
          if (!res?.data?.amount) throw res.data;
          console.log(`SET USERBALANCETYPE ${res?.data?.type === "promotional"}`);
          setUserBalanceType(res?.data?.type === "promotional");
          balanceMutate();
          setTimeout(() => {
            mutate();
          }, 200);
        } catch (error) {
          const errorMsg = error?.response?.data?.error || "er_network";
          setMessage({
            code: `responses.${errorMsg}`,
            type: 0
          });
        } finally {
          StorageService.clearLocalStorageValue("promo");
        }
      };
      redeemPromoCode();
    }
  }, [addTokenHeader, balanceMutate, mutate, setMessage, setUserBalanceType, userData]);
  const {
    isBoostExpired,
    boostProgress
  } = useMemo(() => {
    const diffExpire = rewardsData?.rakeback_boost?.expires_at ? differenceInMilliseconds(removeDateOffset(rewardsData?.rakeback_boost?.expires_at), now) : 0;
    const diffTotal = rewardsData?.rakeback_boost?.expires_at && rewardsData?.rakeback_boost?.started_at ? differenceInMilliseconds(rewardsData?.rakeback_boost?.expires_at, rewardsData?.rakeback_boost?.started_at) : 0;
    const boostProgress = diffExpire / diffTotal * 100;
    const isBoostExpired = diffExpire < 1;
    if (isBoostExpired) {
      return {
        boostProgress: 0,
        isBoostExpired: true
      };
    }
    return {
      boostProgress,
      isBoostExpired
    };
  }, [now, rewardsData?.rakeback_boost]);
  const expireAtRakebackBoost = useMemo(() => {
    return timeLeft(now, removeDateOffset(rewardsData?.rakeback_boost?.expires_at));
  }, [now, rewardsData?.rakeback_boost?.expires_at]);
  const isRakeBoostActive = !!(rewardsData?.rakeback_boost && !isBoostExpired);
  useEffect(() => {
    if (isBoostExpired) {
      getRewardsData();
    }
  }, [isBoostExpired, getRewardsData]);
  useEffect(() => {
    if (rakebackBoosted) {
      getRewardsData().then(() => {
        setRakebackBoosted(false);
      });
    }
  }, [rakebackBoosted, getRewardsData, setRakebackBoosted]);
  return <header className={cn(preferences.chatOpen ? "[@media(max-width:780px)]:fixed sticky" : "sticky", "top-0 left-0 right-0 bg-[#191F3B] z-[5000] transition-opacity duration-300")} data-sentry-component="NewHeader" data-sentry-source-file="index.tsx">
      <HeaderContent isRakeBoostActive={isRakeBoostActive} boostProgress={boostProgress} expireAtRakebackBoost={expireAtRakebackBoost} data-sentry-element="HeaderContent" data-sentry-source-file="index.tsx" />
    </header>;
}
export function MenuButton({
  onMenuToogle
}: {
  onMenuToogle: () => void;
}) {
  return <Button variant="ghost" className="w-[42px] h-[42px] min-h-[42px] px-0 py-0 [&_svg]:size-5" onClick={onMenuToogle} data-sentry-element="Button" data-sentry-component="MenuButton" data-sentry-source-file="index.tsx">
      <MenuIcon data-sentry-element="MenuIcon" data-sentry-source-file="index.tsx" />
    </Button>;
}
export function ChatButton({
  onChatToogle
}: {
  onChatToogle: () => void;
}) {
  return <Button variant="dark" className="w-[48px] h-[48px] !min-h-[48px] px-0 py-0 [&_svg]:size-5 [@media(min-width:1101px)]:flex hidden" onClick={onChatToogle} data-sentry-element="Button" data-sentry-component="ChatButton" data-sentry-source-file="index.tsx">
      <ChatIcon data-sentry-element="ChatIcon" data-sentry-source-file="index.tsx" />
    </Button>;
}
export function SearchButton() {
  const pathname = usePathname();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return <Button variant="dark" className="w-[48px] h-[48px] !min-h-[48px] px-0 py-0 [&_svg]:size-5 [@media(max-width:450px)]:bg-transparent [@media(max-width:450px)]:px-0 [@media(max-width:450px)]:w-[38px] [@media(max-width:450px)]:hover:!bg-transparent [@media(max-width:450px)]:border-none" aria-selected={pathname.includes("search")} onClick={() => setIsModalOpen(true)} data-sentry-element="Button" data-sentry-component="SearchButton" data-sentry-source-file="index.tsx">
      <SearchIcon data-sentry-element="SearchIcon" data-sentry-source-file="index.tsx" />

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen} data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
        <DialogContent className="p-6 w-full max-w-[600px]" data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
          <CloseButton onClick={() => {
          setIsModalOpen(false);
        }} data-sentry-element="CloseButton" data-sentry-source-file="index.tsx" />

          <SearchModal data-sentry-element="SearchModal" data-sentry-source-file="index.tsx" />
        </DialogContent>
      </Dialog>
    </Button>;
}
const ButtonWrapper = ({
  children,
  option
}: ButtonWrapperProps) => <Link href={`/${option.value}`} data-sentry-element="Link" data-sentry-component="ButtonWrapper" data-sentry-source-file="index.tsx">{children}</Link>;
export function CasinoSportsButtons({
  miniVersion
}: {
  miniVersion?: boolean;
}) {
  const pathname = usePathname();
  const urlState = pathname.includes("sports") && !pathname.includes("sportsbook-rules") ? "sportsbook" : "casino";
  const buttonClasses = (isActive: boolean) => cn("border-[1.5px] overflow-hidden", isActive ? "shadow-[inset_0_0_12px_rgba(255,255,255,0.25)]" : "bg-transparent border-transparent");
  const {
    register,
    watch,
    setValue
  } = useForm<{
    "menu-state": "casino" | "sportsbook";
  }>({
    defaultValues: {
      "menu-state": urlState
    }
  });
  const menuState = watch("menu-state");
  const options = useMemo(() => [{
    label: <>
            <FormattedMessage {...getMessage("common_casino")} />
            {menuState === "casino" && <CasinoNavBarIcon />}
          </>,
    value: "casino"
  }, {
    label: <>
            <FormattedMessage {...getMessage("common_sports")} />
            {menuState === "sportsbook" && <SportsNavBarIcon />}
          </>,
    value: "sportsbook"
  }], [menuState]);
  useEffect(() => {
    setValue("menu-state", urlState);
  }, [urlState, setValue]);
  if (miniVersion) {
    return <div className="border-b border-solid border-b-[rgba(255,255,255,0.17)] mb-[15px] pb-[15px] flex items-center justify-center">
        <div className="flex items-center flex-col gap-[2px] p-[3px] bg-gradient-to-b from-[#7C83B1]/20 to-[#5E679E]/20 border border-solid border-[#5E679E]/35 rounded-md">
          <Link href="/casino">
            <Button variant={menuState === "casino" ? "ghost-blue" : "normal"} className={cn("w-[37px] h-[37px] min-h-[37px] px-[18px] [&_svg]:size-5", buttonClasses(menuState === "casino"), menuState === "casino" ? "[&_path]:opacity-100" : "border-none hover:!bg-white/5")}>
              <CasinoNavBarIcon miniVersion />
            </Button>
          </Link>
          <Link href="/sportsbook">
            <Button variant={menuState === "sportsbook" ? "ghost-orange" : "normal"} className={cn("w-[37px] h-[37px] min-h-[37px] px-[18px] [&_svg]:size-5", buttonClasses(menuState === "sportsbook"), menuState === "sportsbook" ? "[&_path]:opacity-100" : "border-none hover:!bg-white/5")}>
              <SportsNavBarIcon miniVersion />
            </Button>
          </Link>
        </div>
      </div>;
  }
  return <ButtonSwitch options={options} register={register("menu-state")} selected={menuState} variant={[menuState === "casino" ? "ghost-blue" : "ghost-orange", "none"]} buttonWrapper={ButtonWrapper} data-sentry-element="ButtonSwitch" data-sentry-component="CasinoSportsButtons" data-sentry-source-file="index.tsx" />;
}
export function ChatHeader({
  isHeader
}: {
  isHeader?: boolean;
}) {
  const {
    data: languages
  } = useLanguages();
  const [lang, setLang] = useAtom(chatLanguageAtom);
  const {
    preferences,
    updatePreference
  } = usePreferences();
  return <div className={cn("px-3 bg-[#12172f] border-l border-solid border-[#FFFFFF]/5 flex items-center justify-between h-full", !isHeader ? "[@media(min-width:1101px)]:hidden flex h-[115px] [@media(max-width:779px)]:h-[74px]" : "")} data-sentry-component="ChatHeader" data-sentry-source-file="index.tsx">
      <Select value={lang} onValueChange={setLang} data-sentry-element="Select" data-sentry-source-file="index.tsx">
        <SelectTrigger className="w-[180px]" data-sentry-element="SelectTrigger" data-sentry-source-file="index.tsx">
          <SelectValue data-sentry-element="SelectValue" data-sentry-source-file="index.tsx" />
        </SelectTrigger>
        <SelectContent data-sentry-element="SelectContent" data-sentry-source-file="index.tsx">
          <SelectGroup data-sentry-element="SelectGroup" data-sentry-source-file="index.tsx">
            {languages?.map(lang => {
            return <SelectItem key={lang.code} value={lang.code}>
                  <div className="flex items-center gap-2 cursor-pointer">
                    <Image src={lang.icon} alt={lang.name} width={16} height={16} />

                    <span className="text-[#ced1df] hover:text-white text-[13px]">
                      {lang.name}
                    </span>
                  </div>
                </SelectItem>;
          })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Button variant="dark" size="sm" className="rounded-full" onClick={() => updatePreference("chatOpen", !preferences.chatOpen)} data-sentry-element="Button" data-sentry-source-file="index.tsx">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="index.tsx">
          <path d="M8.40994 6.99994L12.7099 2.70994C12.8982 2.52164 13.004 2.26624 13.004 1.99994C13.004 1.73364 12.8982 1.47825 12.7099 1.28994C12.5216 1.10164 12.2662 0.99585 11.9999 0.99585C11.7336 0.99585 11.4782 1.10164 11.2899 1.28994L6.99994 5.58994L2.70994 1.28994C2.52164 1.10164 2.26624 0.99585 1.99994 0.99585C1.73364 0.99585 1.47824 1.10164 1.28994 1.28994C1.10164 1.47825 0.995847 1.73364 0.995847 1.99994C0.995847 2.26624 1.10164 2.52164 1.28994 2.70994L5.58994 6.99994L1.28994 11.2899C1.19621 11.3829 1.12182 11.4935 1.07105 11.6154C1.02028 11.7372 0.994141 11.8679 0.994141 11.9999C0.994141 12.132 1.02028 12.2627 1.07105 12.3845C1.12182 12.5064 1.19621 12.617 1.28994 12.7099C1.3829 12.8037 1.4935 12.8781 1.61536 12.9288C1.73722 12.9796 1.86793 13.0057 1.99994 13.0057C2.13195 13.0057 2.26266 12.9796 2.38452 12.9288C2.50638 12.8781 2.61698 12.8037 2.70994 12.7099L6.99994 8.40994L11.2899 12.7099C11.3829 12.8037 11.4935 12.8781 11.6154 12.9288C11.7372 12.9796 11.8679 13.0057 11.9999 13.0057C12.132 13.0057 12.2627 12.9796 12.3845 12.9288C12.5064 12.8781 12.617 12.8037 12.7099 12.7099C12.8037 12.617 12.8781 12.5064 12.9288 12.3845C12.9796 12.2627 13.0057 12.132 13.0057 11.9999C13.0057 11.8679 12.9796 11.7372 12.9288 11.6154C12.8781 11.4935 12.8037 11.3829 12.7099 11.2899L8.40994 6.99994Z" fill="white" data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>
      </Button>
    </div>;
}
export function BalanceWallet() {
  const pathname = usePathname();
  return <>
      <BalanceDropdown data-sentry-element="BalanceDropdown" data-sentry-source-file="index.tsx" />
      <Link href={{
      pathname,
      query: {
        modal: "wallet",
        tab: "deposit"
      }
    }} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Button variant="simple-green" className={cn("h-[48px] min-h-[48px] min-w-[48px] px-[18px] [@media(max-width:767px)]:px-0 [@media(max-width:767px)]:rounded-l-none")} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <WalletIcon data-sentry-element="WalletIcon" data-sentry-source-file="index.tsx" />
          <Dict name="wallet" as="span" section="common" className="[@media(max-width:767px)]:hidden [@media(min-width:1100px)_and_(max-width:1320px)]:hidden block" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        </Button>
      </Link>
    </>;
}
export function LoginRegisterButtons() {
  const pathname = usePathname();
  return <div className="flex items-center gap-[6px]" data-sentry-component="LoginRegisterButtons" data-sentry-source-file="index.tsx">
      <Link href={{
      pathname,
      query: {
        modal: "auth",
        tab: "login"
      }
    }} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Button variant="normal" className="h-[48px] !min-h-[48px] [@media(max-width:350px)]:px-2 px-[18px]" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <Dict name="login" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        </Button>
      </Link>

      <Link href={{
      pathname,
      query: {
        modal: "auth",
        tab: "register"
      }
    }} data-sentry-element="Link" data-sentry-source-file="index.tsx">
        <Button variant="simple-blue" className="h-[48px] !min-h-[48px] [@media(max-width:350px)]:px-2 px-[18px]" data-sentry-element="Button" data-sentry-source-file="index.tsx">
          <Dict name="register" as="span" section="common" data-sentry-element="Dict" data-sentry-source-file="index.tsx" />
        </Button>
      </Link>
    </div>;
}