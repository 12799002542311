"use client";

import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { cn } from "@lib/utils";
import { buttonVariants } from "./button";
const ChevronDown = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" {...props} data-sentry-element="svg" data-sentry-component="ChevronDown" data-sentry-source-file="select.tsx">
    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" data-sentry-element="path" data-sentry-source-file="select.tsx" />
  </svg>;
const ChevronUp = ({
  ...props
}: React.SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" {...props} data-sentry-element="svg" data-sentry-component="ChevronUp" data-sentry-source-file="select.tsx">
    <path d="M8.99961 12.6008C8.87046 12.6008 8.74551 12.5767 8.62476 12.5286C8.50336 12.4806 8.4023 12.4164 8.32158 12.3363L3.86598 7.91185C3.6884 7.73551 3.59961 7.51109 3.59961 7.23857C3.59961 6.96605 3.6884 6.74162 3.86598 6.56528C4.04356 6.38895 4.26956 6.30078 4.544 6.30078C4.81844 6.30078 5.04445 6.38895 5.22203 6.56528L8.99961 10.3164L12.7772 6.56528C12.9548 6.38895 13.1808 6.30078 13.4552 6.30078C13.7297 6.30078 13.9557 6.38895 14.1332 6.56528C14.3108 6.74162 14.3996 6.96605 14.3996 7.23857C14.3996 7.51109 14.3108 7.73551 14.1332 7.91185L9.67764 12.3363C9.58077 12.4325 9.47584 12.5004 9.36284 12.5402C9.24983 12.5806 9.12876 12.6008 8.99961 12.6008Z" fill="#525984" data-sentry-element="path" data-sentry-source-file="DropdownArrow.jsx" data-sentry-element="path"></path>
  </svg>;
const Select = SelectPrimitive.Root;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;
const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
  showChevron?: boolean;
}>(({
  className,
  children,
  showChevron = true,
  ...props
}, ref) => <SelectPrimitive.Trigger ref={ref} className={cn(buttonVariants({
  variant: "dark",
  className: "h-[48px] [@media(max-width:779px)]:h-[42px] [@media(max-width:779px)]:min-h-[42px] w-full flex items-center justify-between px-3 py-0 text-sm focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 hover:bg-[#212747] hover:text-white"
}), className)} {...props}>
    {children}
    {showChevron && <SelectPrimitive.Icon asChild>
        <ChevronUp className="h-4 w-4 opacity-50" />
      </SelectPrimitive.Icon>}
  </SelectPrimitive.Trigger>);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;
const SelectScrollUpButton = React.forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollUpButton>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.ScrollUpButton ref={ref} className={cn("flex cursor-default items-center justify-center py-1", className)} {...props}>
    <ChevronUp className="h-4 w-4" />
  </SelectPrimitive.ScrollUpButton>);
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;
const SelectScrollDownButton = React.forwardRef<React.ElementRef<typeof SelectPrimitive.ScrollDownButton>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.ScrollDownButton ref={ref} className={cn("flex cursor-default items-center justify-center py-1", className)} {...props}>
    <ChevronDown className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>);
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;
const SelectContent = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Content>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>>(({
  className,
  children,
  position = "popper",
  ...props
}, ref) => <SelectPrimitive.Portal>
    <SelectPrimitive.Content ref={ref} className={cn("relative z-[5004] max-h-96 min-w-[8rem] overflow-hidden border bg-[#171d3b] border-[#262b42] border-solid rounded-md text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", position === "popper" && "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1", className)} position={position} {...props}>
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport className={cn("p-1", position === "popper" && "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]")}>
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>);
SelectContent.displayName = SelectPrimitive.Content.displayName;
const SelectLabel = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Label>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.Label ref={ref} className={cn("py-1.5 pl-8 pr-2 text-sm font-semibold", className)} {...props} />);
SelectLabel.displayName = SelectPrimitive.Label.displayName;
const SelectItem = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Item>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>>(({
  className,
  children,
  ...props
}, ref) => <SelectPrimitive.Item ref={ref} className={cn("relative flex w-full cursor-pointer hover:bg-[#212747]/40 h-[42px] select-none items-center py-1.5 px-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[state=checked]:bg-[#212747] rounded-md [&_span]:text-[#ced1df] [&_span]:hover:text-white", className)} {...props}>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>);
SelectItem.displayName = SelectPrimitive.Item.displayName;
const SelectSeparator = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Separator>, React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>>(({
  className,
  ...props
}, ref) => <SelectPrimitive.Separator ref={ref} className={cn("-mx-1 my-1 h-px bg-muted", className)} {...props} />);
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;
export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator, SelectScrollUpButton, SelectScrollDownButton };