import { NODE_API } from "@lib/api/api";
import { useLocale } from "@lib/tools/helpers";
import { useMemo } from "react";
import useSWR from "swr";
export type Language = Readonly<{
  code: string;
  name: string;
  icon: string;
}>;
export type LanguagesResponse = Language[];
export const LANGUAGES_URL = "v1/public/languages";
export const getLanguages = (headers?: Record<string, string>) => NODE_API.get<LanguagesResponse>(LANGUAGES_URL, {
  headers
}).then(res => res.data);
const defaultLanguages = [{
  code: "en",
  icon: "https://cdn.rainbet.com/languages/en/icon.webp",
  name: "English"
}] as const as Language[];
const [defaultLanguage] = defaultLanguages;
export default function useLanguages() {
  const locale = useLocale();
  const swr = useSWR(LANGUAGES_URL, () => getLanguages(), {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    dedupingInterval: 1000 * 60 * 20,
    // 20 minutes
    revalidateIfStale: false,
    keepPreviousData: true
  });
  const languagesMap = useMemo(() => {
    try {
      if (!swr?.data?.length || swr?.error) return {
        [defaultLanguage.code]: defaultLanguage
      };
      return Object.fromEntries(swr.data.map(lang => [lang.code, lang]));
    } catch (error) {
      console.error(error);
      return {
        [defaultLanguage.code]: defaultLanguage
      };
    }
  }, [swr.data, swr.error]);
  return {
    ...swr,
    data: swr?.data?.length ? swr.data : defaultLanguages,
    languagesMap,
    currentLanguage: languagesMap[locale]
  } as const;
}