import { useState, useEffect, RefObject, useCallback } from "react";
export default function useIsHovering(ref: RefObject<HTMLElement>) {
  const [isHovering, setIsHovering] = useState(false);
  const onMouseEnter = useCallback(() => {
    setIsHovering(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, []);
  useEffect(() => {
    const node = ref.current;
    if (!node) return;
    node.addEventListener("mouseenter", onMouseEnter);
    node.addEventListener("mouseleave", onMouseLeave);
    return () => {
      node.removeEventListener("mouseenter", onMouseEnter);
      node.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [ref, onMouseEnter, onMouseLeave]);
  return isHovering;
}